<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home" v-if="isList">
      <b-tabs>
        <b-tab title="Page Settings">
          <bo-card title="Hero Image" v-if="moduleRole('updateHero')">
            <b-row>
              <b-col md="4">
                <bo-card-img title="Produk" :src="uploader(heroImage.ssf_file, '/assets/images/no-slide.jpg')">
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="changeHero()"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>

            <b-modal 
              v-model="heroEditorOpen"
              :title="'Update Hero Image '+ pageTitle"
              size="lg"
              hide-footer
              body-class="p-0"
            >
              <validation-observer
                v-slot="{ handleSubmit }" ref="VFormHero"
              >
                <b-form @submit.prevent="handleSubmit(submitHero)">
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <div class="file-uploader">
                          <label>Hero Image Desktop: </label>
                          <Uploader v-model="heroImage.ssf_file" type="hero-product"/>
                          <VValidate 
                            name="Hero Image Desktop" 
                            v-model="heroImage.ssf_file"
                            :rules="validationHeroImage.ssf_file" 
                          />
                        </div>
                        
                        <div class="file-uploader">
                          <label>Hero Image Mobile: </label>
                          <Uploader v-model="heroImage.ssf_file_mobile" type="hero-product-mobile" use-preview />
                          <VValidate 
                            name="Hero Image Mobile" 
                            v-model="heroImage.ssf_file_mobile"
                            :rules="validationHeroImage.ssf_file_mobile" 
                          />
                        </div>
                      </b-col>
                      <b-col md="6">
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="heroTitleId">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Content ID" 
                              v-model="heroImage.ssf_title_id"
                              :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altId">
                            <template #label>Alt Image ID <strong class="text-danger">*</strong></template>
                            <b-form-input id="altId" v-model="heroImage.ssf_alt_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Alt Image Id" 
                              v-model="heroImage.ssf_alt_id"
                              :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="altIdM">
                            <template #label>Alt Image Mobile ID <strong class="text-danger">*</strong></template>
                            <b-form-input id="altIdM" v-model="heroImage.ssf_alt_mobile_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Alt Image Mobile ID" 
                              v-model="heroImage.ssf_alt_mobile_id"
                              :rules="{...validationHeroImage.ssf_alt_mobile_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="heroTitleEn">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Content EN" 
                              v-model="heroImage.ssf_title_en"
                              :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altEN">                          
                            <template #label>Alt Image EN <strong class="text-danger">*</strong></template>
                            <b-form-input id="altEN" v-model="heroImage.ssf_alt_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Alt Image En" 
                              v-model="heroImage.ssf_alt_en"
                              :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altEnM">
                            <template #label>Alt Image Mobile EN <strong class="text-danger">*</strong></template>
                            <b-form-input id="altIEn" v-model="heroImage.ssf_alt_mobile_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Alt Image Mobile EN" 
                              v-model="heroImage.ssf_alt_mobile_en"
                              :rules="{...validationHeroImage.ssf_alt_mobile_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </validation-observer>
            </b-modal>


          </bo-card>
          <bo-card title="Products" v-if="data.length > 0" style="cursor:pointer;">
            <draggable v-if="moduleRole('edit')" tag="div" class="row gutter-2 draggable" v-model="data" @start="startDrag" @end="endDrag" :draggable="moduleRole('edit') ? '.slide' :'.no-slide'">
              <b-col md="4" class="slide mb-3" v-for="(product,k) in (data||[])" :key="k">
                <div class="main-card product-card">
                  <div class="bullet-cta">
                    <b-button
                      class="btn-icon btn-rounded"
                      variant="outline-warning"
                      v-if="moduleRole('edit')"
                      :to="{ name: routeName, params: { pageSlug: product.mp_id } }"
                      v-b-tooltip.hover="'Update Product'"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button
                      class="btn-icon btn-rounded"
                      variant="outline-danger"
                      v-if="moduleRole('delete')"
                      v-b-tooltip.hover="'Delete Product'"
                      @click="doDelete(k,product)"
                    >
                      <i class="fas fa-trash-alt" />
                    </b-button>
                  </div>
                  <div class="main-card-body">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img
                          :src="uploader(product.mp_logo)"
                          :alt="product.mp_title_id"
                          width="66"
                          height="80"
                          class="product-card__img img-responsive"
                        />
                      </div>
                      <div class="col-8">
                        <h3 class="product-card__title">{{ product.mp_title_id }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </draggable>
          </bo-card>
          <bo-card title="Products" v-if="data.length == 0">
            <b-row class="gutter-2">
              <b-col lg="12">
                <div class="text-center">
                <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                </div>
              </b-col>
            </b-row>
          </bo-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Product List SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id"/>
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en"/>
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="4" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id"/>
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="4" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en"/>
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>



    <template v-else>
      <validation-observer ref="VForm"
      >
      <b-form @submit.prevent="submitProduct">
        <b-tabs v-model="tabIndex">
          <b-tab title="Page Settings">
            <bo-card title="Hero Image">
              <b-row>
                <b-col md="4">
                  <bo-card-img title="Produk" :src="uploader(row.mp_hero_image, '/assets/images/no-slide.jpg')">
                    <template #buttons>
                      <b-button
                        variant="secondary"
                        size="sm"
                        pill
                        class="btn-icon"
                        v-b-tooltip.hover="'Update'"
                        @click="changeHeroDetail"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                  <small id="Hero Image" 

                  v-if="!row.mp_hero_image || !row.mp_hero_image_mobile || !row.mp_hero_alt_id || !row.mp_hero_alt_en || !row.mp_hero_alt_mobile_id || !row.mp_hero_alt_mobile_en" 
                  class="label_error" style="">The hero image field is required</small>
                </b-col>
              </b-row>

              
              <b-modal
                title="Update Product Hero Images"
                v-model="heroEditorOpenDetail"
                size="xl"
                cancel-variant="secondary btn-rounded"
                ok-variant="primary btn-rounded"
                ok-title="Save Changes"
              >
                <b-row>
                  <b-col md="6">
                    <div class="mb-5">
                      <Uploader v-model="row.mp_hero_image" type="hero-products-list"/>
                    </div>
                    <div class="mb-5">
                      <Uploader v-model="row.mp_hero_image_mobile" type="hero-product-list-mobile" use-preview/>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <b-card class="border border-bottom-0 mb-0">
                      <template #header>
                        <h5 class="card-title">Content (ID)</h5>
                      </template>
                      <b-form-group label-for="heroTitleId">
                        <template #label>Alt Image<strong class="text-danger">*</strong></template>
                        <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_hero_alt_id" id="heroTitleId" />
                      </b-form-group>
                      <b-form-group label-for="heroTitleIDMob">
                        <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                        <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_hero_alt_mobile_id" id="heroTitleIDMob" />
                      </b-form-group>
                    </b-card>
                    <b-card class="border">
                      <template #header>
                        <h5 class="card-title">Content (EN)</h5>
                      </template>
                      <b-form-group label-for="heroTitleEn">
                        <template #label>Alt Image <strong class="text-danger">*</strong></template>
                        <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_hero_alt_en" id="heroTitleEn" />
                      </b-form-group>
                      <b-form-group label-for="heroTitleEnMob">
                        <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                        <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_hero_alt_mobile_en" id="heroTitleEnMob" />
                      </b-form-group>
                    </b-card>
                  </b-col>
                </b-row>
              </b-modal>


            </bo-card>
            <bo-card title="Product Information">
              <b-row>
                <b-col md="8" lg="9">
                  <b-row no-gutters>
                    <b-col md="6">
                      <b-card class="border">
                        <template #header>
                          <h5 class="card-title">Content (ID)</h5>
                        </template>
                        <b-form-group label-for="productTitleId">
                          <template #label>Product Name <strong class="text-danger">*</strong></template>
                          <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_title_id" id="productTitleId" />
                          <VValidate 
                            name="Title ID" 
                            v-model="row.mp_title_id"
                            :rules="{...mrValidation.mp_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />    
                        </b-form-group>
                        <b-form-group label-for="productDescId">
                          <template #label>Product Description <strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="articleContent"
                            :value.sync="row.mp_desc_id"
                            :customToolbar="configCKEditor"
                          />
                          <VValidate 
                            name="Description ID" 
                            v-model="row.mp_desc_id"
                            :rules="mrValidation.mp_desc_id" 
                          />
                        </b-form-group>
                      </b-card>
                    </b-col>
                    <b-col md="6">
                      <b-card class="border border-left-0">
                        <template #header>
                          <h5 class="card-title">Content (EN)</h5>
                        </template>
                        <b-form-group label-for="productTitleEn">
                          <template #label>Product Name <strong class="text-danger">*</strong></template>
                          <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_title_en" id="productTitleEn" />
                          <VValidate 
                            name="Title EN" 
                            v-model="row.mp_title_en"
                            :rules="{...mrValidation.mp_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />    
                        </b-form-group>
                        <b-form-group label-for="productDescEn">
                          <template #label>Product Description <strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="articleContent"
                            :value.sync="row.mp_desc_en"
                            :customToolbar="configCKEditor"
                          />
                          <VValidate 
                            name="Description EN" 
                            v-model="row.mp_desc_en"
                            :rules="mrValidation.mp_desc_en" 
                          />    
                        </b-form-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="4" lg="3">
                  <Uploader v-model="row.mp_logo" type="product-logo"/>
                  <VValidate 
                    name="Logo" 
                    v-model="row.mp_logo"
                    :rules="mrValidation.mp_logo" 
                  />    
                  <b-form-group class="mt-3" label-for="altId">    
                    <template #label>Alt Image Id<strong class="text-danger">*</strong></template>
                    <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_alt_id" id="altId" placeholder="Alt Image ID"/>
                    <VValidate 
                      name="Alt Logo ID" 
                      v-model="row.mp_alt_id" 
                      :rules="{...mrValidation.mp_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                    />
                  </b-form-group>
                  
                  <b-form-group label-for="altEn">         
                    <template #label>Alt Image En<strong class="text-danger">*</strong></template>
                    <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_alt_en" id="altEn" placeholder="Alt Image EN"/>
                    <VValidate 
                      name="Alt Logo EN" 
                      v-model="row.mp_alt_en" 
                      :rules="{...mrValidation.mp_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                    />
                  </b-form-group>
                  <b-form-group>                   
                    <div class="row">
                      <div class="col-md-3">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio1" v-model="row.mp_is_active" value="Y"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio1">Active</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio3" v-model="row.mp_is_active" value="N"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio3">Inactive</label>
                        </div>
                      </div>
                      <VValidate name="Status" v-model="row.mp_is_active"
                        :rules="'required'" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </bo-card>


            <bo-card title="Content Management">
              <div 
                v-for="(section,key) in (row.section||[])"
                :key="key">
                <bo-card
                  v-if="section.isActive"
                  class="border"
                  :title="section['mps_title_id'+ (key+1)] ? 'Section ' + section['mps_title_id'+ (key+1)] : 'New Section'"
                >
                  <b-row>
                    <b-col md="5">
                      <b-form-row>
                        <b-col md="6">
                          <b-form-group label="Content Type">
                            <v-select v-model="section['mps_type'+ (key+1)]" :options="cardOptions" @input="cardChange(key)" :reduce="v => v.value" />

                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Grid Count">
                            <b-form-radio-group v-model="section['mps_grid_count'+ (key+1)]" :options="gridOptions" />                      
                            </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-card class="border mb-0">
                        <template #header>
                          <h5 class="card-title">Content (ID)</h5>
                        </template>
                        <b-form-group label-for="productTitleId">
                          <template #label>Section Title <strong class="text-danger">*</strong></template>
                          <b-form-input id="sectionTitleId" @keyup="removeWildChar" @keydown="removeWildChar" v-model="section['mps_title_id'+ (key+1)]"/>
                          <VValidate 
                            :name="'Section Title Id '+(key+1)" 
                            v-model="section['mps_title_id'+ (key+1)]"
                            :rules="{required: 1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />    

                        </b-form-group>
                        <b-form-group class="mb-0" label-for="productDescEn">
                          <template #label>Section Description <small>(Optional)</small></template>
                          <b-form-textarea id="sectionDescId" @keyup="removeWildChar" @keydown="removeWildChar" v-model="section['mps_description_id'+ (key+1)]" rows="2"/>
                          <VValidate 
                            :name="'Section Description Id '+(key+1)" 
                            v-model="section['mps_description_id'+ (key+1)]"
                            :rules="{min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />    
                        </b-form-group>
                      </b-card>
                      <b-card class="border border-top-0 mb-0">
                        <template #header>
                          <h5 class="card-title">Content (EN)</h5>
                        </template>
                        <b-form-group label-for="productTitleEn">
                          <template #label>Section Title <strong class="text-danger">*</strong></template>
                          <b-form-input id="productTitleEn" @keyup="removeWildChar" @keydown="removeWildChar" v-model="section['mps_title_en'+ (key+1)]"/>
                          <VValidate 
                            :name="'Section Title En '+(key+1)" 
                            v-model="section['mps_title_en'+ (key+1)]"
                            :rules="{required: 1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />    
                        </b-form-group>
                        <b-form-group class="mb-0" label-for="productDescEn">
                          <template #label>Section Description <small>(Optional)</small></template>
                          <b-form-textarea id="productDescEn" @keyup="removeWildChar" @keydown="removeWildChar" v-model="section['mps_description_en'+ (key+1)]" rows="2" />
                          <VValidate 
                            :name="'Section Description En '+(key+1)" 
                            v-model="section['mps_description_en'+ (key+1)]"
                            :rules="{min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-card>


                    </b-col>
                    <b-col md="7">
                      <b-row
                        cols="1"
                        :cols-md="section['mps_grid_count'+(key+1)]"
                        :cols-xl="section['mps_grid_count'+(key+1)] > 2 ? section['mps_grid_count'+(key+1)] : null"
                        align-h="center"
                        class="gutter-2"
                      >
                        <b-col v-for="(content,key2) in (section['content'+(key+1)]||[])" :key="key2">
                          <bo-product-detail-card v-bind="content" 
                          :card-type="section['mps_type'+(key+1)] == 2 ? 'checked' : section['mps_type'+(key+1)] == 3 ? 'with-image' : '' ">
                            <template #buttons>
                              <div class="bullet-cta">
                                <b-button
                                  class="btn-rounded btn-icon"
                                  variant="outline-warning"
                                  v-b-tooltip.hover="'Update Content'"
                                  @click="toggleEditContent(content,key2,key)"
                                >
                                  <i class="fas fa-pencil-alt" />
                                </b-button>
                                <b-button
                                  class="btn-rounded btn-icon"
                                  variant="outline-danger"
                                  v-b-tooltip.hover="'Delete Content'"
                                  @click="deleteContent(key,key2)"
                                >
                                  <i class="fas fa-trash-alt" />
                                </b-button>
                              </div>
                            </template>
                            <template #footer v-if="content.note">
                              <div class="main-card-footer">
                                <div class="small">{{ content.note }}</div>
                              </div>
                            </template>
                          </bo-product-detail-card>
                        </b-col>
                        <b-col md="12" class="mt-5">
                          <div class="text-center">
                            <b-button variant="outline-primary" @click="addContent(key)">
                              <i class="fas fa-plus mr-2"></i>Add New Content
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="12" class="text-right">
                      <b-button
                        variant="outline-danger"
                        class="btn-rounded"
                        @click="deleteSection(key)"
                      >
                        <i class="fas fa-trash-alt mr-2"></i>
                        Delete Section
                      </b-button>
                    </b-col>
                  </b-row>
                </bo-card>
                
                <validation-observer
                  v-slot="{ handleSubmit }" ref="VFormContent"
                >
                  <b-modal
                    no-close-on-backdrop 
                    title="Update Product Content"
                    v-model="modalContent"
                    @ok.prevent="handleSubmit(submitModalContent())"
                    size="xl"
                    cancel-variant="secondary btn-rounded"
                    ok-variant="primary btn-rounded"
                    ok-title="Save Changes"
                  >
                    <b-row>
                      <b-col lg="5" v-if="contentRow.contentType == 3">
                        <Uploader v-model="contentRow.mpsi_image" type="product-logo-desc" />
                        <VValidate 
                          name="Image" 
                          v-model="contentRow.mpsi_image"
                          :rules="{required: 1}" 
                        />
                      </b-col>

                      <b-col :lg="contentRow.contentType == 2 || contentRow.contentType == 1 ? '12' : '7'" >
                        <b-card class="border mb-0 border-bottom-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="contentTitleId" v-if="contentRow.contentType == 3 || contentRow.contentType == 2">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="contentTitleId" v-model="contentRow.mpsi_title_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Title Id" 
                              v-model="contentRow.mpsi_title_id"
                              :rules="{required: 1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />    
                          </b-form-group>
                          <b-form-group label-for="contentDescriptionId">
                            <template #label>Description <strong v-if="contentRow.contentType == 1" class="text-danger">*</strong></template>
                            <b-form-textarea rows="4" id="contentDescriptionId" v-model="contentRow.mpsi_desc_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Description Id" 
                              v-model="contentRow.mpsi_desc_id"
                              :rules="{required: (contentRow.contentType == 1 ? true : false), min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />    
                          </b-form-group>
                          <b-form-group label-for="contentNotesId" v-if="contentRow.contentType == 3 || contentRow.contentType == 2">
                            <template #label>Notes<small>(Optional)</small></template>
                            <b-form-input id="contentNotesId" v-model="contentRow.mpsi_notes_id" @keyup="removeWildChar" @keydown="removeWildChar"/>                            
                            <VValidate 
                              name="Notes Id" 
                              v-model="contentRow.mpsi_notes_id"
                              :rules="{min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />

                          </b-form-group>
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="contentTitleEn" v-if="contentRow.contentType == 3 || contentRow.contentType == 2">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="contentTitleEn" v-model="contentRow.mpsi_title_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Title En" 
                              v-model="contentRow.mpsi_title_en"
                              :rules="{required: 1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="contentDescriptionEn">
                            <template #label>Description <strong class="text-danger" v-if="contentRow.contentType == 1">*</strong></template>
                            <b-form-textarea rows="4" id="contentDescriptionEn" v-model="contentRow.mpsi_desc_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Description En" 
                              v-model="contentRow.mpsi_desc_en"
                              :rules="{required : contentRow.contentType == 1 ? true : false, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />    
                          </b-form-group>
                          <b-form-group label-for="contentNotesEn" v-if="contentRow.contentType == 3 || contentRow.contentType == 2">
                            <template #label>Notes<small>(Optional)</small></template>
                            <b-form-input id="contentNotesEn" v-model="contentRow.mpsi_notes_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                            <VValidate 
                              name="Notes En" 
                              v-model="contentRow.mpsi_notes_en"
                              :rules="{min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-card>
                      </b-col>
                    </b-row>
                    <!--for submit -->
                    <b-button type="submit" class="d-none"></b-button>
                  
                  </b-modal>
                </validation-observer>
              </div>



              <div class="text-center">
                <b-button
                  variant="outline-info"
                  class="btn-rounded"
                  @click="addSection()"
                >
                  <i class="fas fa-plus mr-2"></i>
                  Add Section
                </b-button>
              </div>

            
            </bo-card>

          </b-tab>
          <b-tab title="SEO Settings">  
            <b-card no-body>
              <b-card-header>
                <b-card-title title-tag="h5">Product SEO Settings</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_meta_title_id" id="homeMetaTitle" placeholder="Meta Title"/>
                      <VValidate 
                        name="Meta Title Product ID" 
                        v-model="row.mp_meta_title_id" 
                        :rules="{...mrValidation.mp_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_meta_title_en" id="homeMetaTitle" placeholder="Meta Title"/>
                      <VValidate 
                        name="Meta Title Product EN" 
                        v-model="row.mp_meta_title_en" 
                        :rules="{...mrValidation.mp_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="4" @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_meta_description_id" id="homeMetaDesc" placeholder="Meta Description"/>
                      <VValidate 
                        name="Meta Description Product ID" 
                        v-model="row.mp_meta_description_id" 
                        :rules="{...mrValidation.mp_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="4" @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mp_meta_description_en" id="homeMetaDesc" placeholder="Meta Description"/>
                      <VValidate 
                        name="Meta Description Product EN" 
                        v-model="row.mp_meta_description_en" 
                        :rules="{...mrValidation.mp_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags v-model="row.mp_meta_keyword_id" id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags v-model="row.mp_meta_keyword_en" id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
        <b-card>
          <div class="text-right">
            <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
          </div>
        </b-card>
      </b-form>
      </validation-observer>
    </template>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import BoProductDetailCard from '../../../components/backend/BoProductDetailCard.vue'
import Gen from '@/libs/Gen'
import CKEditor from '@/components/CKEditor'


import draggable from 'vuedraggable'
let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    BoCardImg,
    BoProductDetailCard,
    CKEditor,
    draggable
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {
      idKey:'mp_id',
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      data: [],
      row:{},
      modalContent : false,
      mode: '',
      mrValidation: {},
      addkey : 0,
      sectionLength : 0,
      
      seoRow: {},
      heroEditorOpen: false,
      heroEditorOpenDetail: false,
      contentRow: {},
      cardOptions: [
        { label: 'Regular Card', value: 1 },
        { label: 'Checked Card', value: 2 },
        { label: 'Card with Image', value: 3 },
      ],
      gridOptions: [1, 2],
      tabIndex : 0,
      configCKEditor: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote'
      ]
     
    }
  },
  methods: {
    toggleEditContent(data,key,keyPr) {
      this.contentRow = data
      this.contentRow['contentType'] = this.row.section[keyPr]['mps_type'+(keyPr+1)]
      this.modalContent = true
      this.mode = key
    },
    deleteContent(keySection,key) {
      this.row.section[keySection]['content'+(keySection+1)].splice(key,1)
    },
    addContent(key){
      this.contentRow = {}
      this.contentRow['contentType'] = this.row.section[key]['mps_type'+(key+1)]
      this.modalContent = true
      this.mode = 'add'    
      this.addKey = key
    },
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    changeHeroDetail(){
      this.heroEditorOpenDetail = !this.heroEditorOpenDetail
    },
    addSection(){
      let length = this.row.section.length
      let data = {}
      
      data["id"] = length + 1,
      data["mps_title_id" + (length+ 1)] = ""
      data["mps_title_en" + (length+ 1)] = ""
      data["mps_type" + (length+ 1)] = 1
      data["mps_grid_count" + (length+ 1)] = 1
      data["mps_description_id" + (length+ 1)] = ""
      data["mps_description_en" + (length+ 1)] = ""
      data["content" + (length+ 1)] = []
      data["isActive"] = true
      this.row.section.push(data)
      this.sectionLength = this.sectionLength + 1
    },
    changeVal(e,key){
      this.row.section[key] = e.target.value
    },
    deleteSection(key){
      let length = 0
      for(let i = 0; i < this.row.section.length; i++){
          if(this.row.section[i].isActive){
              length = length + 1
          }
      }
      this.row.section[key].isActive = false
      this.sectionLength = this.sectionLength - 1
    },
    cardChange(key){
      this.row.section[key]['content'+ (key+1)] = []
    },
    submitModalContent(){
      // let key = this.row.section.findIndex(v => v.id == id)
      // sementara pake ini this.$refs nya ga jalan
      if(this.contentRow.contentType == 3){
        if(this.contentRow.mpsi_image && this.contentRow.mpsi_title_id && this.contentRow.mpsi_title_en){
          let mode = this.mode
          if(mode == 'add'){
              this.contentRow.last_section = null
              this.row.section[this.addKey]['content'+(this.addKey+1)].push(this.contentRow)
          }
         
          this.modalContent = false
          this.contentRow = {}
        }
      }
      else if(this.contentRow.contentType == 2){
        if(this.contentRow.mpsi_title_id && this.contentRow.mpsi_title_en){
          let mode = this.mode
          if(mode == 'add'){
              this.contentRow.last_section = null
              this.row.section[this.addKey]['content'+(this.addKey+1)].push(this.contentRow)
          }
          this.modalContent = false
          this.contentRow = {}
        }
      }
      else{
        if(this.contentRow.mpsi_desc_id && this.contentRow.mpsi_desc_en){
          let mode = this.mode
          if(mode == 'add'){
              this.contentRow.last_section = null
              this.row.section[this.addKey]['content'+(this.addKey+1)].push(this.contentRow)
          }
          this.modalContent = false
          this.contentRow = {}
        }
      }

      
       
    },
    startDrag(){
      $(".sortable-fallback").removeAttr("style")
      $(".sortable-fallback").css("display","none")
    },
    endDrag() {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: 'sort',
            data: this.data
          }
        },
        "POST"
      )
    },
    submitProduct(){
      if(!this.row.mp_hero_image || !this.row.mp_hero_image_mobile || !this.row.mp_hero_alt_id || !this.row.mp_hero_alt_en || !this.row.mp_hero_alt_mobile_id || !this.row.mp_hero_alt_mobile_en){
        document.getElementById("Hero Image").style.display = 'block'
        this.tabIndex = 0
        return
      }
      if(!this.row.mp_title_id || !this.row.mp_title_en || !this.row.mp_desc_id || !this.row.mp_desc_en || !this.row.mp_logo || !this.row.mp_alt_id || !this.row.mp_alt_en){
        setTimeout(()=>{
          window.scroll({
            top: 0,
            left: 0,
          })
        },1000)
        this.tabIndex = 0
      }
      if(!this.row.mp_meta_title_id || !this.row.mp_meta_title_en ||!this.row.mp_meta_description_id || !this.row.mp_meta_description_en){
        this.tabIndex = 1
      }
      
      this.$refs['VForm'].validate().then(success => {
        if (!success) return   
        var newArrContent = []
        for(let i = 0; i < this.row.section.length; i++){
          if(this.row.section[i].isActive){
            newArrContent.push({
              mps_title_id : this.row.section[i]['mps_title_id'+(i+1)],
              mps_title_en : this.row.section[i]['mps_title_en'+(i+1)],
              mps_type : this.row.section[i]['mps_type'+(i+1)],   
              mps_grid_count : this.row.section[i]['mps_grid_count'+(i+1)],   
              mps_description_id : this.row.section[i]['mps_description_id'+(i+1)],   
              mps_description_en : this.row.section[i]['mps_description_en'+(i+1)],   
              content : this.row.section[i]['content'+(i+1)]
            })
          }
        }
        var payload = this.row
        payload.sectionPost = newArrContent
        
        this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: this.isAdd?'add':'update'
          }, payload),
          (type, resp) => {
            if (type == 'success') {
              if(Object.keys(resp.data.rowAlgoliaID).length){
                this.submitCRUDToAlgolia('product_id_',resp.data.rowAlgoliaID)
              }
              if(Object.keys(resp.data.rowAlgoliaEN).length){
                this.submitCRUDToAlgolia('product_en_',resp.data.rowAlgoliaEN)
              }
              this.apiGet()
              window.scrollTo(0, 0)
              this.$router.push({name:this.$route.name}).catch(()=>{})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                  })
                  return this.$swal.fire("Blocked With Validation!", msg)
                } else {
                  return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return this.$swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm'
        )
      })
    },
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'row.mp_hero_alt_id'(v){
        this.row.mp_hero_alt_mobile_id = v
    },
    'row.mp_hero_alt_en'(v){
        this.row.mp_hero_alt_mobile_en = v
    },
    'row.mp_title_id'(v){
      if(this.isAdd){
        this.row.mp_meta_title_id = v
        this.row.mp_alt_id = v
      }
    },
    'row.mp_title_en'(v){
      if(this.isAdd){
        this.row.mp_meta_title_en = v
        this.row.mp_alt_en = v
      }
    },
    'row.mp_desc_id'(v){
      if(this.isAdd && this.$route.params.pageSlug){
        this.row.mp_meta_description_id = v.replace(/<[^>]*>?/gm, '')
      }
    },
    'row.mp_desc_en'(v){
      if(this.isAdd && this.$route.params.pageSlug){
        this.row.mp_meta_description_en = v.replace(/<[^>]*>?/gm, '')      
      }
    },

    // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    


    'row.mp_meta_title_id'(v){
      let el = document.getElementById('Meta Title Product ID')
      if(el){
        let showcount = document.getElementById('Meta Title Product ID' + 'count')
        let cErr = document.getElementById('Meta Title Product ID' + 'count-err')        
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'row.mp_meta_title_en'(v){
      let el = document.getElementById('Meta Title Product EN')
      if(el){
        let showcount = document.getElementById('Meta Title Product EN' + 'count')
        let cErr = document.getElementById('Meta Title Product EN' + 'count-err')        
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'row.mp_meta_description_id'(v){
      let el = document.getElementById('Meta Description Product ID')
      if(el){
        let showcount = document.getElementById('Meta Description Product ID' + 'count')
        let cErr = document.getElementById('Meta Description Product ID' + 'count-err')        
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
     
    },
    'row.mp_meta_description_en'(v){
      let el = document.getElementById('Meta Description Product EN')
      if(el){
        let showcount = document.getElementById('Meta Description Product EN' + 'count')
        let cErr = document.getElementById('Meta Description Product EN' + 'count-err')                
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    }

  }
}
</script>
