<template>
  <div :class="`main-card pt-3 ${cardClasses(cardType)}`">
    <slot name="buttons" />
    <slot>
      <div class="main-card-body" v-if="cardType == 'checked'">
        <div class="text-card-head">
          <div class="text-card-head__check">
            <check-icon size="14"/>
          </div>
          <h3 class="text-card-head__title" v-if="mpsi_title_id">{{ mpsi_title_id }}</h3>
        </div>
        <p class="text-card__desc" v-if="mpsi_desc_id">{{ mpsi_desc_id }}</p>
      </div>
      <div class="main-card-body text-center" v-else>
        <template v-if="cardType == 'with-image'">
          <b-img
            fluid
            :src="uploader(mpsi_image)"
            :alt="mpsi_title_id"
            width="80"
            height="80"
            class="service-card__img"
          />
          <h3 class="service-card__title" v-if="mpsi_title_id">{{ mpsi_title_id }}</h3>
          <p class="service-card__desc" v-if="mpsi_desc_id">{{ mpsi_desc_id }}</p>
        </template>
        <p class="mb-0" v-else>{{ mpsi_desc_id }}</p>
      </div>
    </slot>
    <slot name="footer" />
  </div>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import { CheckIcon } from 'vue-feather-icons'

export default {
  extends: GlobalVue,
  components:{
    CheckIcon
  },
  props: {
    cardType: String, 
    mpsi_title_id: String,
    mpsi_desc_id: String,
    mpsi_image: String,
  },
  methods: {
    cardClasses(type) {
      if (type == 'checked') return 'text-card text-card--checked'
      if (type == 'with-image') return 'service-card '
      return ''
    }
  }
}
</script>